import { Flex, Text } from "@chakra-ui/react";
import { NextPage } from "next";
import Image from "next/image";

const NotFoundPage: NextPage = () => (
    <Flex direction={{ base: "column" }} minH={"100vh"} bgColor="white">
        <Flex justifyContent={{ base: "center" }} paddingTop="20">
            <Image
                alt="Login Image"
                src="/404-assets/404 Error_Line.png"
                width="100"
                height="100"
            />
        </Flex>
        <Flex direction="column">
            <Text fontSize="lg" fontWeight="semibold" textAlign="center">
                Oops, sorry...
            </Text>
            <Text fontSize="sm" textAlign="center">
                It seems that the page you were trying to access does not exist
            </Text>
        </Flex>
    </Flex>
);

export default NotFoundPage;
